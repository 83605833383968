import React from "react";
import "../style/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode,faDesktop,faCompassDrafting,faCropSimple,faNetworkWired} from "@fortawesome/free-solid-svg-icons"; // faCropSimple 
import { faAppStore } from "@fortawesome/free-brands-svg-icons";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css'

const Services = () => {
  
  return (
    <>
      <div id="services">
        <div className="container">
          <h1 className="sub-title">Our Services</h1>
          <p>
          </p>
          <div className="services-list">
            <div>
              <FontAwesomeIcon icon={faDesktop} className={"facode"} />
              <h2>Construction Electrical CAD Drawings Work 
              </h2>
              <p>
              Effective Electrical Drafting Services Are Indispensable, Yet Navigating Their Implementation Within The Design Process Can Be Overwhelming. Recognizing This Challenge, Our Team At Engineering Drafting Services Is Committed To Ensuring That Your Designs Seamlessly Incorporate Electrical Drafting To Align With Your Project Objectives. We Understand The Myriad Responsibilities Of Business Ownership, And We Appreciate The Importance Of Delivering Safe And Functional Products To Your Clients.
              </p>
              <p className="popp">            
              <Popup
                trigger={<button className="button">MORE</button>}
                modal
                nested
              >
                {close => (
                  <div className="modal">
                    <button className="close" onClick={close}>
                      &times;
                    </button>
                    <div className="header"> Construction Electrical CAD Drawings Work </div>
                    <div className="content">
                      {' '}
                      Electrical Plans:<br/><br/>

                      Power Distribution Plan: Detailed Layout Illustrating The Distribution Of Electrical Power Throughout The Building, Including Panel Locations, Feeder Routes, And Branch Circuits.
                      Lighting Plan: Diagrams Specifying The Placement And Type Of Lighting Fixtures, Switches, And Controls For Interior And Exterior Lighting.
                      Receptacle Plan: Layout Indicating The Location And Type Of Electrical Outlets, Including Receptacles For General Use, Specialized Equipment, And Appliances.
                      <br/><br/>
                      Electrical Details:<br/><br/>

                      Wiring Diagrams: Schematic Drawings Illustrating The Wiring Connections And Circuitry For Lighting, Power Outlets, And Specialized Equipment.
                      Panel Schedules: Tabulated Schedules Providing Information On Panel Boards, Circuit Breakers, Feeder Sizes, And Load Calculations.
                      Conduit And Raceway Layouts: Drawings Showing The Routing And Sizing Of Conduits, Cable Trays, And Raceways For Electrical Wiring Installations.
                      <br/><br/>
                      Fire Alarm And Life Safety Systems:<br/>
                      <br/>
                      Fire Alarm Layout: Diagrams Indicating The Location Of Fire Alarm Devices, Including Smoke Detectors, Heat Detectors, Strobes, And Pull Stations.
                      Emergency Lighting Plan: Layout Specifying The Placement Of Emergency Lighting Fixtures For Egress Lighting In Case Of Power Failure Or Emergency Situations.
                      <br/><br/>
                      Communication And Data Systems:<br/><br/>

                      Structured Cabling Plan: Drawings Illustrating The Layout Of Structured Cabling Systems For Voice, Data, And Video Communications, Including Cable Routes, Termination Points, And Equipment Locations.
                      Security System Layout: Diagrams Showing The Placement Of Security Cameras, Access Control Devices, And Intrusion Detection Sensors For Surveillance And Security Monitoring.
                      <br/><br/>
                      Electrical Site Plans:<br/><br/>

                      Site Electrical Distribution Plan: Overview Drawings Depicting The Electrical Distribution Layout For The Entire Site, Including Transformer Locations, Switchgear Installations, And Utility Connections.
                      Temporary Power Plan: Layout Detailing The Provision Of Temporary Electrical Power During Construction, Including Temporary Panels, Power Outlets, And Distribution Methods.
                      <br/><br/>
                      As-Built Documentation:<br/><br/>

                      Record Drawings: Updated Drawings Reflecting Any Changes Or Modifications Made To The Electrical Systems During Construction, Including Deviations From The Original Design.
                      Final Documentation: Comprehensive Documentation Of The Completed Electrical Installations, Including Accurate Measurements, As-Installed Equipment, And Connections.
                      <br/><br/>
                      Coordination And Collaboration:<br/><br/>

                      Coordination Meetings: Regular Meetings With Other Trades And Stakeholders To Review And Coordinate Electrical Drawings With Architectural, Structural, And Mechanical Plans.
                      Bim Integration: Integration Of Electrical Drawings With Building Information Modeling (BIM) Software For Enhanced Coordination And Clash Detection During The Design And Construction Phases.
                      <br/><br/>
                      Conclusion:<br/><br/>
                        The Scope Of Work For Electrical Construction Drawings Encompasses A Comprehensive Set Of Plans, Details, And Documentation To Facilitate The Installation And Integration Of Electrical Systems Within The Building And Site. By Providing Accurate And Detailed Drawings, We Ensure The Safety, Functionality, And Efficiency Of The Electrical Infrastructure Throughout The Construction Process.
                                            
                    </div>
                    <div className="actions">
                      <button
                        className="pop-btn"
                        onClick={() => {
                          console.log('modal closed ');
                          close();
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </Popup>
              </p>
              {/* <a href="#contact">more</a> */}
            </div>
            <div>
              <FontAwesomeIcon icon={faNetworkWired} className={"facode"} />
              <h2>Low-Voltage Switchgear Electrical CAD Drawings Work</h2>
              <p>Develop Sld Depicting The Electrical Distribution System, Including Incoming Power Sources, Distribution Boards,
              And Low-Voltage Switchgear.Include Details Of Circuit Breakers, Switches, Transformers, And Other Essential Components.
              Ensure Clarity And Accuracy In Representing Electrical Connections, Ratings, And Protective Devices.
              Incorporate Labeling And Annotation For Easy Interpretation By Engineers And Technicians         
              </p>
              <p className="popp">            
              <Popup
                  trigger={<button className="button">MORE</button>}
                  modal
                  nested
                >
                  {close => (
                    <div className="modal">
                      <button className="close" onClick={close}>
                        &times;
                      </button>
                      <div className="header"> Low-Voltage Switchgear Electrical CAD Drawings Work</div>
                      <div className="content">
                        {' '}
                        Single Line Diagram (SLD) Work:<br/><br/>

                        Develop Sld Depicting The Electrical Distribution System, Including Incoming Power Sources, Distribution Boards, And Low-Voltage Switchgear.
                        Include Details Of Circuit Breakers, Switches, Transformers, And Other Essential Components.
                        Ensure Clarity And Accuracy In Representing Electrical Connections, Ratings, And Protective Devices.
                        Incorporate Labeling And Annotation For Easy Interpretation By Engineers And Technicians.<br/><br/>

                        General Arrangement Drawing (GA) Work:<br/><br/>

                        Create Ga Drawings Illustrating The Layout And Arrangement Of Low-Voltage Switchgear Within The Designated Facility.
                        Provide Detailed Views Of Switchgear Assemblies, Including Dimensions, Clearances, And Interconnections.
                        Incorporate Annotations To Specify Equipment Locations, Cable Routing, And Access Requirements.
                        Ensure Compliance With Space Constraints, Safety Regulations, And Ergonomic Considerations.
                        Coordinate With Architectural And Structural Drawings To Integrate Switchgear Installations Seamlessly Into The Building Layout.<br/><br/>

                        Revision And Approval Process:<br/><br/>

                        Facilitate Review And Approval Of Drafted Sld And Ga Drawings By Relevant Stakeholders, Including Engineering Teams, Project Managers, And Regulatory Authorities.
                        Address Feedback And Incorporate Necessary Revisions To Ensure Alignment With Project Requirements And Standards.
                        Maintain Version Control And Documentation Of All Revisions For Traceability And Audit Purposes.<br/><br/>

                        Coordination With Design And Engineering Teams:<br/><br/>

                        Collaborate Closely With Design And Engineering Teams To Integrate Sld And Ga Drawings Into Overall Project Plans.
                        Incorporate Design Changes And Updates Into Drafted Drawings In A Timely Manner.
                        Communicate Effectively To Resolve Any Discrepancies Or Conflicts Between Design Intent And Practical Implementation.<br/><br/>

                        Quality Assurance And Compliance:<br/><br/>

                        Implement Quality Control Measures To Verify The Accuracy And Completeness Of Drafted Sld And Ga Drawings.
                        Ensure Compliance With Relevant Industry Standards, Codes, And Regulations Governing Electrical Installations And Drawings.
                        Conduct Internal Reviews And Inspections To Identify And Rectify Any Errors Or Omissions Proactively.<br/><br/>

                        Documentation And Archiving:<br/><br/>

                        Maintain Organized Documentation Of All Drafted Sld And Ga Drawings, Including File Management And Version Tracking.
                        Establish Archiving Procedures To Retain Historical Records For Future Reference And Documentation Purposes.
                        Ensure Accessibility Of Drawings To Authorized Personnel Throughout The Project Lifecycle And Beyond.<br/><br/>

                        Conclusion:<br/><br/>
                        
                        The Drafting Work List Outlined Above Serves As A Comprehensive Guide For Creating Accurate And Detailed Single Line Diagram (SLD) And General Arrangement (GA) Drawings For Low-Voltage Switchgear Installations. By Adhering To These Guidelines, We Aim To Deliver Drawings That Meet The Highest Standards Of Quality, Clarity, And Compliance.
                      </div>
                      <div className="actions">
                        <button
                          className="pop-btn"
                          onClick={() => {
                            console.log('modal closed ');
                            close();
                          }}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                </Popup>
                </p>
              {/* <a href="#contact">more</a> */}
            </div>

            <div>
              <FontAwesomeIcon icon={faCompassDrafting} className={"facode"} />
              <h2>CAD Drawings Conversion Services
              </h2>
              <p>At Engineering Drafting Services , We Specialize In Providing Expert CAD Drawing Conversion Services To Help You Digitize, Update, Or Optimize Your Existing Drawings And Designs. Our Team Of Experienced CAD Technicians And Drafters Can Assist You In Converting Your Hand-Drawn Sketches, PDFs, Scanned Images, Or Outdated CAD Files Into Accurate And Editable Digital Formats.
              </p>
              <p className="popp">            
              <Popup
                trigger={<button className="button">MORE</button>}
                modal
                nested
              >
                {close => (
                  <div className="modal">
                    <button className="close" onClick={close}>
                      &times;
                    </button>
                    <div className="header"> Construction Electrical CAD Drawings Work </div>
                    <div className="content">
                      {' '}
                      At Engineering Drafting Services , We Specialize In Providing Expert CAD Drawing Conversion Services To Help You Digitize, Update, Or Optimize Your Existing Drawings And Designs. Our Team Of Experienced CAD Technicians And Drafters Can Assist You In Converting Your Hand-Drawn Sketches, PDFs, Scanned Images, Or Outdated CAD Files Into Accurate And Editable Digital Formats.
                      <br />
                      <br />
                      Auto CAD To Microstation CAD Conversion:<br/><br/>

                      AutoCAD Drawing (.dwg) Format  To Microstation (.dgn) Format.<br/><br />
                      
                      PDF To CAD Conversion:<br/><br/>
                      Transforming Your PDF Files Into Fully Editable CAD Drawings That Can Be Modified And Updated As Needed.<br/><br/>

                      Hand Sketch To CAD: <br/><br/>Converting Your Hand-Drawn Sketches Or Blueprints Into Precise CAD Drawings For Enhanced Clarity And Ease Of Use.
                      
                    </div>
                    <div className="actions">
                      <button
                        className="pop-btn"
                        onClick={() => {
                          console.log('modal closed ');
                          close();
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </Popup>
              </p>
              {/* <a href="#contact">more</a> */}
            </div>
            
            <div>
              <FontAwesomeIcon icon={faCode} className={"facode"} />
              <h2>Web Designing</h2>
              <p>
                Creating Visually Stunning And User-Centric Web Experiences Is
                Not Just A Job For Me; It'S My Passion. 
              </p>
              <a href="#contact">MORE</a>
            </div>
            {/* Uncomment the following code if you want to include UI/UX Designing */}
            <div>
              <FontAwesomeIcon icon={faCropSimple} className={"facode"} />
              <h2>UI/UX Designing</h2>
              <p>
              A UI/UX designer comprehends people, articulates their issues, and designs creative solutions that can be prototyped and tested. UI/UX designers employ design thinking and incorporate design principles and frameworks to develop the most optimal solution for a problem. Though UI and UX are 2 separate fields that lead with different OKRs. 
              </p>
              <a href="#contact">MORE</a>
            </div>
            <div>
              <FontAwesomeIcon icon={faAppStore} className={"facode"} />
              <h2>Web App Development</h2>
              <p>
                Transforming Ideas Into Dynamic And Functional Web Applications
                Is Not Just My Profession; It'S My Forte. As A Dedicated Web App
                Developer, I Believe In Creating Web Applications That Not Only
                Meet But Exceed User Expectations. Each Line Of Code Is An
                Opportunity To Enhance User Experiences And Provide Solutions
                That Make A Meaningful Impact.
              </p>
              <a href="#contact">MORE</a>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default Services;
