import React from 'react';
import "../style/style.css";
import logo from "../images/Logo-eds.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane, faPhoneVolume } from "@fortawesome/free-solid-svg-icons";


import {
  faFacebook,
    faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const iconsColor={
  color:'red'
}
const heartColor={
    color:'red'
}

const Footer = () => {
  return (
    <div className="footer">
          <img src={logo} alt="Logo" className="footer-logo" />
      <h1>EDSOLAR - GROUP</h1>  <br/>
            <p>
              <FontAwesomeIcon icon={faPaperPlane} style={iconsColor}/> info@edsolargroup.com
            </p><br/>
            <p>
              <FontAwesomeIcon icon={faPhoneVolume} style={iconsColor}/> +91 9600526174
            </p>   <br/>

      <p>© 2024 EDSOLAR - GROUP | All rights reserved</p><br/>
      <div className="social-icons">
              <a href="https://www.facebook.com/mohamed.faisal.52493">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              {/* <a href="https://www.instagram.com/md.isham03/">
                <FontAwesomeIcon icon={faInstagram} />
              </a> */}
              <a href="https://www.linkedin.com/in/mohamed-faisal-28b3576b">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              {/* <a href="https://github.com/Mdisham03">
                <FontAwesomeIcon icon={faGithub} />
              </a> */}
            </div><br/>
      <p>
        Made By <FontAwesomeIcon icon={faHeart} style={heartColor}/> EDSOLAR - GROUP
      </p>
    </div>
  );
};

export default Footer;
