import React from "react";
import Header from "../Header/Header";
import About from "../About/About";
import Services from "../MyServices/Services";
import Contact from "../Contact/Contact";
import Popup from "../Popup/Popup";

function Home() {
  return (
    <div>
      <Header />
      <Services />
     
      <Contact />
      <About />
     
      <Popup />
    </div>
  );
}

export default Home;
