import React, { useState } from "react";
import "../style/style.css";
import Footer from "../Footer/Footer";


const About = () => {
  const [activeTab, setActiveTab] = useState("skills");

  const openTab = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
     
      <div id="about">
        <div className="container">
          <div className="row">
            {/* <div className="about-col-1">
              <img src={Photo} alt="About Me" />
            </div> */}
            <div className="about-col-2">
              <h1 className="sub-title">About Us</h1>
              <p>
              
   At Engineering Drafting Services, We Are A Team Of Dedicated Professionals Specializing In Providing High-Quality Drafting And CAD Services. We Have Established Ourselves As A Trusted Partner For Clients Seeking Precise And Efficient Drafting Solutions.<br/><br/>
              </p>
              <div className="tab-titles">
                <p
                  className={`tab-links ${
                    activeTab === "skills" ? "active-link" : ""
                  }`}
                  onClick={() => openTab("skills")}
                >
                 Why Choose Us
                </p>
                <p
                  className={`tab-links ${
                    activeTab === "education" ? "active-link" : ""
                  }`}
                  onClick={() => openTab("education")}
                >
               Our Mission
                </p>
              </div>

              <div
                className={`tab-contents ${
                  activeTab === "skills" ? "active-tab" : ""
                }`}
                id="skills"
              >
                <p><b>Expertise :</b> <br/><br/>
                Our Team Consists Of Skilled Drafters And CAD Technicians Who Are Proficient In The Latest Software Tools And Techniques.<br/><br/>
                <b>Accuracy :</b> <br/><br/>
                We Pay Attention To Detail To Ensure That Every Drawing And Design Meets The Highest Standards Of Precision And Quality.<br/><br/>
                <b>Timely Delivery :</b> <br/><br/>
                We Understand The Importance Of Deadlines And Strive To Deliver Projects On Time, Every Time.<br/><br/>
                <b>Customer Satisfaction :</b> <br/><br/>
                Customer Satisfaction Is Our Top Priority, And We Work Closely With Clients To Ensure Their Needs Are Met And Expectations Are Exceeded.</p><br/>
                <p>   Whether You Need Assistance With 2D Drafting & 3D Modeling, Electrical Drawings Or Any Other Drafting Services Like Drawings Conversion, Engineering Drafting Services Is Here To Help. Contact Us Today To Learn More About How We Can Support Your Project And Turn Your Vision Into Reality.</p>             
              </div>
              <div
                className={`tab-contents ${
                  activeTab === "education" ? "active-tab" : ""
                }`}
                id="education"
              >
                <p>    Our Mission Is To Deliver Top-Notch Drafting Services Tailored To Meet The Unique Needs Of Each Client. Whether You Are An Architect, Engineer, Contractor, Or Designer, We Are Committed To Helping You Bring Your Ideas To Life Through Our Expertise In CAD Software And Drafting Techniques.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
