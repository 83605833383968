import React, { useState } from "react";
import "../style/style.css";
import logo from "../images/Logo-eds.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const clr = {
    color: "white",
  };

  return (
    <>
      <div id="header">
        <div className="container">
          <nav>
            <img src={logo} alt="Logo" className="logo" />
            <ul id="sidemenu" style={{ right: menuOpen ? "0" : "-200px" }}>
              {/* <ul id="sidemenu" className={menuOpen ? 'open' : ''}> */}
              <li >
                <a href="#header" style={{color:'#fff'}}  onClick={closeMenu} >
                  HOME
                </a>
              </li>
              <li>
                <a href="#services" style={{color:'#fff'}} onClick={closeMenu}>
                  SERVICES
                </a>
              </li>
             
              <li>
                <a href="#contact" style={{color:'#fff'}} onClick={closeMenu}>
                  CONTACT
                </a>
              </li>
              <li>
                <a href="#about" style={{color:'#fff'}} onClick={closeMenu}>
                  ABOUT
                </a>
              </li>

              <FontAwesomeIcon
                icon={faXmark}
                className="fa-solid fa-bars"
                style={clr}
                onClick={closeMenu}
              />
            </ul>
            <FontAwesomeIcon
              icon={faBars}
              className="fa-solid fa-bars"
              style={clr}
              onClick={openMenu}
            />
            {/* <i className="fa-solid fa-bars" onClick={openMenu}></i> */}
          </nav>
          <div className="header-text">
           
            <div class="text-box">           
            <h1>EDS - GROUP</h1>
            <h2><span>E</span>NGINEERING <span>D</span>RAFTING <span>S</span>ERVICES</h2>
            <h4>&</h4>
            <h2><span>W</span>EB <span>S</span>OULTION </h2>
            <p>   At Engineering Drafting Services, We Are A Team Of Dedicated Professionals Specializing In Providing High-Quality Drafting And CAD Services. We Have Established Ourselves As A Trusted Partner For Clients Seeking Precise And Efficient Drafting Solutions.
            </p>
            <a href="#services" class="visit-btn">Explore Now </a>
            <a href="#contact" class="visit-btn">Contact Us</a>
        </div>         
          
          </div>
        </div>
      </div>
      <hr />
    </>
  );
};

export default Header;
