import React from "react";
import "../style/style.css";

const Contact = () => {
 
  return (
    <div id="contact">
      <div className="container">2
        <div className="row ">
          <div className="contact-right ">
            <h1>Contact Here !</h1>
            <form
              action="https://api.web3forms.com/submit"
              method="POST"             
            >
              <input
                type="hidden"
                name="access_key"
                value="06d155ea-a86a-420e-ad2a-41c0e6ee8e6d"
              />
              <label htmlFor="Name">Enter Your Name</label>
              <br />
              <input type="text" name="Name" placeholder="Your Name" required />
              <label htmlFor="email">Enter Your Email</label>
              <br />
              <input
                type="email"
                name="Email"
                placeholder="Your Email"
                required
              />
              <br />
              <label htmlFor="Service">Select a Service</label>
              <br />
              <select
                name="Service"
                className="options"
                required
              >
                <option value="">choose services</option>
                <option value="Construction Electrical CAD Drawings Work">Construction Electrical CAD Drawings Work</option>
                <option value="Low-Voltage Switchgear Electrical CAD Drawings Work">Low-Voltage Switchgear Electrical CAD Drawings Work</option>
                <option value="CAD Drawings Conversion Services">CAD Drawings Conversion Services</option>
                <option value="Web Designing">Web Designing</option>
                <option value="UI/UX Designing">UI/UX Designing</option>
                <option value="Web App Development">Web App Development</option>
                <option value="Other CAD Services">Other CAD Services</option>
              </select>
              <br /><br />
              <label htmlFor="msg">Enter Your Message</label>
              <br />
              <textarea
                name="Message"
                rows="6"
                placeholder="Your Message"
                required
              ></textarea>
              <button type="submit" className="btn btn2">
                Submit
              </button>
            </form>
          </div>
        
        </div>
      </div>
    </div>
  );
};

export default Contact;
